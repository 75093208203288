/*  --------------------------------------------------

Hook useRefScrollProgress

Funktion: Was macht der Hook? 
    - Spielt die prozentualen Start- und Endwerte, relativ zur Dokumenten-Scrolllänge, aus, wenn ein ref übergeben wird.
    - Die Start- und Endwerte können widerum genutzt werden, um z.B. Animationen an den 
    Scrollfortschritts eines spezifischen Blocks zu verknüpfen.

Beispiel: 
  -  const { start, end } = useRefScrollProgress(myRef);

----------------------------------------------------- */

/*
  Takes an optional component ref (or returns a new one)
  and returns the ref, the scroll `start` and `end` percentages
  that are relative to the total document progress.
*/

import { useState, useLayoutEffect } from "react";

function useRefScrollProgress(inputRef) {
	const ref = inputRef;

	const [start, setStart] = useState(null);
	const [end, setEnd] = useState(null);

	useLayoutEffect(() => {
		if (!ref.current) {
			return;
		}

		const rect = ref.current.getBoundingClientRect();
		const scrollTop =
			window.pageYOffset || document.documentElement.scrollTop;
		const offsetTop = rect.top + scrollTop;

		setStart(offsetTop / document.body.clientHeight);
		setEnd((offsetTop + rect.height) / document.body.clientHeight);
	});

	return { ref, start, end };
}

export default useRefScrollProgress;
