import React, { useState, useEffect } from 'react';
import ImgTextModule from './elements/ImgTextModule';
import { Text, Container, Flex, Box, Button } from '@chakra-ui/react';
import RouterLink from './Utility/Routing/RouterLink';
import FadeInAnimation from './UX/FadeInAnimation';
import AnimatedCountUp from './UX/AnimatedCountUp';
import InViewTrigger from './Utility/UX/InViewTrigger';
import { Modal } from './elements/Modal';
import { Modal1 } from './elements/Modal1';

export default function KontaktFooter() {
  const [isToggled, setToggle] = useState(null);
  const [isToggledL, setToggleL] = useState(null);

  return (
    <ImgTextModule
      src='646845_Laptop_Hands_Scrolling_Keyboard_By_Adrian_Vidak_Artlist_HD.mp4'
      delay={0.3}
      duration={0.5}
      threshold={0.3}
      initialY={10}
      initialX={10}
      video={true}
      mediaOpacity={0.7}
      bgColor='black'
    >
      <Container variant='layoutContainer' mb={16} pt={16} minH={'55vh'}>
        <Box p={{ base: 0, lg: '8' }}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
            mr={8}
          >
            <Text
              color='white'
              fontWeight='normal' mx={'auto'} textAlign={'center'}
              fontSize={{ base: '2xl', lg: '2xl' }}
            >
              So finden Sie zu uns:
            </Text>
          </FadeInAnimation>
          <Flex>
            <Box pt={6} mx={'auto'} textAlign={'center'}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.4}
                initialX={0}
                initialY={-10}
              >
                <Text
                  lineHeight={'undercut'}
                  color='brand.green'
                  fontWeight='normal'  mx={'auto'} textAlign={'center'}
                  fontSize={{ base: '3xl', lg: '6xl' }}
                >
                  Unsere Standorte
                </Text>
              </FadeInAnimation>
            </Box>
          </Flex>

          <FadeInAnimation
            mt={4}
            threshold={0.4}
            delay={1}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text
              color='brand.blue'
              mb={8}
              maxW={{ base: '100%', lg: '50%' }}
              fontSize={{ base: 'xl', lg: '3xl' }}
              lineHeight={'short'}  mx={'auto'} textAlign={'center'}
            >
              Wir freuen uns auf
              <br /> Ihren persönlichen Kontakt
            </Text>
          </FadeInAnimation>
          <Flex flexWrap={'wrap'} justifyContent={'center'}>
            <FadeInAnimation threshold={0.4} duration={1} delay={0.3} mr={8}>
              <Button
                onClick={() => setToggle(true)}
                mt={2}
                px={14}
                pt={4}
                pb={9}
                fontSize='md'
                textAlign='center'
                variant={'solid'}
                color='white'
                bg='brand.violet'
              >
                Wilhelmshaven
              </Button>
            </FadeInAnimation>
            <FadeInAnimation threshold={0.4} duration={1} delay={0.7} mr={8}>
              <Button
                onClick={() => setToggleL(true)}
                mt={2}
                px={14}
                pt={4}
                pb={9}
                fontSize='md'
                textAlign='center'
                variant={'solid'}
                color='white'
                bg='brand.violet'
              >
                Lübeck
              </Button>
            </FadeInAnimation>
            <FadeInAnimation threshold={0.4} duration={1} delay={0.7} mr={8}>
              <RouterLink isExternal={'false'} href={'/kontakt'}>
                <Button
                  mt={2}
                  px={14}
                  pt={4}
                  pb={9}
                  fontSize='md'
                  textAlign='center'
                  variant={'solid'}
                  color='black'
                  bg='brand.green'
                >
                  Kontakt
                </Button>
              </RouterLink>
            </FadeInAnimation>
          </Flex>
        </Box>
      </Container>
      <Modal isToggled={isToggled} setToggle={setToggle}></Modal>
      <Modal1 isToggledL={isToggledL} setToggleL={setToggleL}></Modal1>
    </ImgTextModule>
  );
}
