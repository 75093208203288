import { Box, Button, Text, Heading, Flex } from '@chakra-ui/react';
import { Link } from 'gatsby';
import RouterLink from '../Utility/Routing/RouterLink';
import { motion, AnimatePresence } from 'framer-motion';
import React from 'react';
import GImage from '../Utility/GImage';

export const Modal = ({ isToggled, setToggle, children }) => {
  return (
    <AnimatePresence>
      {isToggled && (
        <motion.div
          position='relative'
          top='0px'
          left={{ base: '0%', lg: '25%' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          mt={'25vh'}
        >
          <Box
            w={{ base: '85vw', md: '70vw' }}
            mt={{ base: '10vh', md: '15vh' }}
            ml={{ base: '5vw', md: '15vw' }}
            as={motion.div}
            position='fixed'
            zIndex='99999'
            initial={{ y: 50, scale: 0.2 }}
            animate={{ y: 0, scale: 1 }}
            exit={{ y: -50, scale: 0.1 }}
            p={8}
            top={'0'}
            boxShadow='base'
            bg={'#ffffff'}
            borderRadius='11px'
          >
            <Flex flexDirection={{ base: 'column-reverse', md: 'row' }}>
              <Box mr={4} minW='30%'>
                <Heading color='brand.blue'>Wilhelmshaven</Heading>
                <Text>Ulmenstraße 21</Text>
                <Text>26384 Wilhelmshaven</Text>
                <Text color='brand.violet' fontWeight='bold' mt={8}>
                  <RouterLink isExternal={true} href={'Tel: 04421 80 99 00'}>
                    Tel. 04421 80 99 00
                  </RouterLink>
                </Text>
                <Text color='brand.red' fontWeight='bold' mt={4}>
                  <a href='mailto:whv@kdw-hr.de'>whv@kdw-hr.de</a>
                </Text>
                <Button
                  mt={12}
                  px={6}
                  pt={3}
                  pb={4}
                  fontSize='xs'
                  textAlign='center'
                  variant={'solid'}
                  onClick={() => setToggle(false)}
                >
                  Schliessen
                </Button>
              </Box>
              <GImage src='wilhelmshaven.png' alt='Wilhelmshaven' />
            </Flex>
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
