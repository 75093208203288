import { motion, useViewportScroll, useTransform } from "framer-motion";
import React, { useEffect, useState } from "react"
import { Box } from "@chakra-ui/react";
const MotionBox = motion(Box)
const ProgressLine = ({color, start, end, scrollStartFactor, scrollEndFactor, blockHeight}) => {
  const [progress, setProgress] = useState(false);
  const { scrollYProgress } = useViewportScroll(); // zeigt uns den Progress der kompletten Seite (Prozentual)
  const scrollThreshold = blockHeight * scrollStartFactor;
  const scrollEndThreshold = blockHeight * scrollEndFactor;
  const yRange = useTransform(
    scrollYProgress,
    [start - scrollThreshold, end - scrollEndThreshold],
    [0, 100]
  );
  useEffect(() => yRange.onChange((v) => setProgress(v)), [yRange]);
  return (
    <MotionBox 
      position='absolute'
      top='0'
      left='0'
      w='4px'
      bg={color}
      animate={{height: progress + 'vh'}}
      zIndex={9999}
    />
  )
}


export default ProgressLine