/* --------------------------------------------------

Component: MagicText

Funktion:
  - Fades in text based on scrollProgress.

Was gibt es zu beachten? 
  - Viele unterschieldiche Props
  - Hintergrund kann übergeben werden.
  - Komponente besteht aus einem Parent und vielen <Word/>-Komponenten

Beispiel:
    <MagicText
    fontSize={'5xl'}
    fontWeight="bold"
    text="Wir lieben die moderne Mystik, verrückte Ideen und die eines jeden Menschen. Lasse Dich von uns dazu inspirieren, mutig Deinen Verstand zu dehnen, um die verborgenen Welten zu erforschen und ein glückliches Leben zu erschaffen."
    gap='1rem'
    scrollLength={'300vh'}
    videoSrc={Video}
    scrollStartFactor={-0.2}
    scrollEndFactor={0.2}
    textColor={'var(--chakra-colors-brand-goldLight)'}
  />


  TODO: Für die Scroll-Animationen die richtigen ScrollAnimation-Komponenten einfügen.

----------------------------------------------------- */

import { Box, Text } from "@chakra-ui/react";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import GVideo from "../Utility/GVideo";
import useRefScrollProgress from "../Utility/Hooks/useRefScrollProgress";
import ProgressLine from "./ProgressLine";
const MotionText = motion(Text);

// Word-Component

const Word = ({
	start,
	end,
	fraction,
	index,
	word,
	blockHeight,
	scrollStartFactor,
	scrollEndFactor,
	fontSize,
	gap,
	textColor,
	fontWeight,
	coloranimtext,
}) => {
	const [isComplete, setIsComplete] = useState(false);
	const { scrollYProgress } = useViewportScroll(); // zeigt uns den Progress der kompletten Seite (Prozentual)
	const scrollThreshold = blockHeight * scrollStartFactor;
	const scrollEndThreshold = blockHeight * scrollEndFactor;
	const yRange = useTransform(
		scrollYProgress,
		[start - scrollThreshold, end - scrollEndThreshold],
		[0, 100]
	);

	useEffect(
		() =>
			yRange.onChange((v) => setIsComplete(v >= fraction * (index + 1))),
		[yRange]
	);
	return (
		<MotionText
			as="span"
			display="inline-block"
			whiteSpace="nowrap"
			mr={{ base: "0.5ch", lg: "0.55ch" }}
			fontSize={fontSize}
			fontWeight={fontWeight}
			animate={{
				opacity: isComplete ? 1 : 0.01,
				color: isComplete ? coloranimtext : "#000000",
				y: isComplete ? 0 : 0,
				transition: {
					duration: 0.125,
				},
			}}>
			{word}
		</MotionText>
	);
};

//Complete Component
export default function MagicText({
	text,
	quoteOrigin,
	fontSize,
	scrollLength,
	gap,
	videoSrc,
	scrollStartFactor,
	coloranimtext,
	scrollEndFactor,
	textColor,
	fontWeight,
	showProgressCircle,
	progressColor,
	showProgressLine,
	progressLineColor,
}) {
	const myRef = useRef();
	const { start, end } = useRefScrollProgress(myRef);
	const blockHeight = end - start;
	const MotionBox = motion(Box);
	const WordArray = text.split(" ");
	const waL = WordArray.length;
	const fraction = 100 / waL;
	const scrollThreshold = blockHeight * scrollStartFactor;
	const scrollEndThreshold = blockHeight * scrollEndFactor;
	const { scrollYProgress } = useViewportScroll();
	const opacityEl = useTransform(
		scrollYProgress,
		[start + blockHeight * 0.5, end - blockHeight * 0.2],
		[0, 1]
	);
	console.log("OPCA_ALERT:!!");
	console.log(scrollThreshold);
	console.log("start:!!");
	console.log(start);
	console.log("Blockheight:!!");
	console.log(blockHeight);
	console.log("OPCA_ALERT:!!");

	return (
		<MotionBox
			ref={myRef}
			top={0}
			h={scrollLength}
			mx={"auto"}
			maxW={"100%"}>
			<MotionBox
				className="item"
				h={"100vh"}
				display={"flex"}
				justifyContent="center"
				alignContent={"center"}
				position={"sticky"}
				top={0}
				left={0}
				bg="black">
				{showProgressLine && (
					<ProgressLine
						color={progressLineColor}
						start={start}
						end={end}
						blockHeight={blockHeight}
						scrollStartFactor={scrollStartFactor}
						scrollEndFactor={scrollEndFactor}
					/>
				)}
				<Box
					position={"absolute"}
					top={0}
					zIndex={1112}
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					h={"100%"}
					w={{ base: "90%", lg: "60%" }}
					opacity="1">
					<Box
						maxW={"container.xxl"}
						mx="auto"
						display="flex"
						flexWrap="wrap"
						justifyContent="center"
						p={8}
						opacity={1}>
						{WordArray.map((word, index) => {
							return (
								<Word
									key={index}
									word={word}
									coloranimtext={coloranimtext}
									fraction={fraction}
									index={index}
									start={start}
									end={end}
									blockHeight={blockHeight}
									scrollStartFactor={scrollStartFactor}
									scrollEndFactor={scrollEndFactor}
									fontSize={fontSize}
									gap={gap}
									textColor={textColor}
									fontWeight={fontWeight}
								/>
							);
						})}
						<MotionBox
							mt={12}
							w="100%"
							display={"flex"}
							justifyContent={"end"}
							style={{ opacity: opacityEl }}>
							<Text
								color="white"
								textAlign="center"
								w="100%"
								fontSize={"2xl"}>
								{quoteOrigin}
							</Text>
						</MotionBox>
					</Box>
				</Box>
				{videoSrc && (
					<GVideo
						src={videoSrc}
						style={{
							zIndex: "0",
							height: 100 + "vh",
							width: 100 + "%",
							objectFit: "cover",
							opacity: "0.8",
						}}
					/>
				)}
			</MotionBox>
		</MotionBox>
	);
}
