/*  --------------------------------------------------

Component ScrollVideo
    
----------------------------------------------------- */

import { Box } from '@chakra-ui/react';
import { useTransform, useViewportScroll } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
const MotionBox = motion(Box);
export default function ScrollVideo({
  start,
  end,
  startValue,
  endValue,
  blockHeight,
  scrollStartFactor,
  scrollEndFactor,
  videosrc,
  fps,
  scrollHeight,
  showProgressLine,
  progressLineColor,
}) {
  const { scrollYProgress } = useViewportScroll();
  const scrollThreshold = blockHeight * scrollStartFactor;
  const scrollEndThreshold = blockHeight * scrollEndFactor;
  const yRange = useTransform(
    scrollYProgress,
    [start - scrollThreshold, end - scrollEndThreshold],
    [startValue, endValue]
  );

  const videoRef = useRef();
  const [progress, setProgress] = useState(false);
  useEffect(() => {
    videoRef.current.pause();
  });
  useEffect(() => {
    yRange.onChange((v) => {
      if (videoRef) {
        let frames = videoRef.current.duration * fps;
        let videoTime = Math.floor(((v * frames) / endValue / fps) * 100) / 100;
        // v * maxFrames / maxValue
        videoRef.current.currentTime = isNaN(videoTime) ? 0 : videoTime;
        setProgress(v);
      }
    });
  }, [yRange]);
  return (
    <>
      <Box
        gridRow='1'
        gridColumn='1'
        position='sticky'
        top='0'
        height={scrollHeight}
        bg='black'
      >
        {showProgressLine && (
          <MotionBox
            position='sticky'
            top='0'
            left={{ base: '0', md: '48vw' }}
            w={{ base: '4px', md: '2px' }}
            bg='brand.yellow'
            animate={{ height: progress + 'vh' }}
            zIndex={9999}
          />
        )}
        <video
          ref={videoRef}
          muted
          preload='auto'
          autoPlay
          style={{
            width: '100%',
            objectFit: 'cover',
            position: 'sticky',
            top: '0',
            height: '100vh',
            opacity: '0.3',
          }}
        >
          <source type='video/mp4' src={videosrc} />
        </video>
      </Box>
    </>
  );
}
