import {
  Box,
  Container,
  Button,
  Heading,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import RouterLink from '../components/Utility/Routing/RouterLink';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollAnimationContainer from '../components/UX/ScrollAnimationContainer';
import ScrollVideo from '../components/UX/ScrollVideo';
import ImgTextModule from '../components/elements/ImgTextModule';
import FadeInAnimation from '../components/UX/FadeInAnimation';
import MagicText from '../components/UX/MagicText';
import video from '/src/video/backgroundwork.mp4';
import TeamTri from '../components/TeamTri';
import KontaktFooter from '../components/KontaktFooter';
const IndexPage = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode('light');
  });

  return (
    <>
      <Helmet>
        <title>KDW | Personalmanagement und Managementberatung</title>
        <meta
          name='description'
          content='kdw HR bietet Kunden Dienstleistungen von der Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung bis hin zu Management- und Fördermittelberatung im gesamten Personalmanagementbereich. Arbeitnehmer finden bei uns langfristige Karriereoptionen genauso wie neue Herausforderungen vornehmlich im Industrie-, Gewerbe- und Officesegment.'
        />
        <link rel='canonical' href='https:/kdw-hr.de' />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Helmet>
      <ImgTextModule
        src={
          '240822_Businessman_Man_Silhouette_Dolly_In_By_Monster_Filmmakers_Artlist_HD.mp4'
        }
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h='85vh'
        mediaOpacity={0.8}
        bgColor='brand.gray.100'
      >
        <Container variant='layoutContainer' mt={8}>
          <Box p={{ base: 0, lg: '8' }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            ></FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.4}
              initialX={0}
              initialY={-10}
            >
              <Text
                color='white'
                textAlign={'center'}
                fontWeight='normal'
                fontSize={{ base: 'md', lg: 'lg' }}
              >
                Wir sind Mittler, Begleiter, Entwickler
              </Text>
              <Text
                color='brand.yellow'
                fontWeight='normal'
                textAlign={'center'}
                fontSize={{ base: '4xl', lg: '7xl' }}
              >
                Über uns.
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color='brand.gray.1000'
                mb={4}
                textAlign={'center'}
                mx={'auto'}
                maxW={{ base: '100%', lg: '70%' }}
                fontSize={{ base: '3xl', lg: '4xl' }}
                lineHeight={'short'}
              >
                kdw HR ist ein
                <br /> inhabergeführtes Unternehmen.
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color='brand.gray.1000'
                mb={24}
                mx={'auto'}
                maxW={{ base: '100%', lg: '50%' }}
                fontSize={{ base: 'lg', lg: 'xl' }}
                lineHeight={'short'}
                textAlign={'center'}
              >
                Unser Ziel ist ein nachhaltiges Personal- und Karrieremanagement
                für unsere Partner und Kunden. Dabei scheuen wir uns nicht, neue
                Wege zu beschreiten und zielorientiert zu begleiten.
              </Text>
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>
      <ScrollAnimationContainer scrollLength={'300vh'}>
        {(start, end, blockHeight) => (
          <>
            <ScrollVideo
              start={start}
              end={end}
              blockHeight={blockHeight}
              scrollStartFactor={0}
              scrollEndFactor={0}
              startValue={0}
              endValue={100}
              videosrc={video}
              fps={25}
              scrollHeight='300vh'
              showProgressLine={true}
              progressLineColor='#00ff99'
            />
            <Container position='absolute' variant='layoutContainer' mt={8}>
              <Box
                display='grid'
                alignContent='center'
                w={{ base: '80%', md: '50%' }}
                h='100vh'
                pr='25%'
                position='sticky'
                top='0'
                mx='auto'
              >
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.0}
                  duration={1}
                  initialX={0}
                  initialY={10}
                >
                  <Heading
                    color='brand.violet'
                    fontWeight='normal'
                    fontSize={{
                      base: '4xl',
                      lg: '6xl',
                    }}
                  >
                    Wer wir sind
                  </Heading>
                </FadeInAnimation>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.4}
                  duration={0.8}
                  initialX={0}
                  initialY={10}
                >
                  <Text
                    my={8}
                    color='white'
                    fontWeight='normal'
                    fontSize={{
                      base: 'lg',
                      lg: 'xl',
                    }}
                  >
                    Jeder Gesellschafter für sich verfügt über eine
                    jahrzehntelange Erfahrung in den jeweiligen Sachgebieten
                    unserer angebotenen Dienstleistungen.
                  </Text>
                </FadeInAnimation>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.8}
                  duration={0.8}
                  initialX={0}
                  initialY={10}
                >
                  <Text
                    color='white'
                    fontWeight='normal'
                    fontSize={{
                      base: 'lg',
                      lg: 'xl',
                    }}
                  >
                    Durch die Expertise jedes Einzelnen konnte über die Jahre
                    genauso ein hervorragendes Netzwerk von Kooperationspartner
                    und Kunden aufgebaut werden wie eine positive Reputation im
                    Mitarbeiterbereich.
                  </Text>
                </FadeInAnimation>
              </Box>
            </Container>
            <Container
              position='absolute'
              top={'75vh'}
              variant='layoutContainer'
              mt={8}
            >
              <Box
                w={{ base: '80%', md: '80vw' }}
                h='100vh'
                pl={{ base: '0%', md: '50vw' }}
                pr={{ base: '0%', md: '10vw' }}
                top='0'
                mx='auto'
                display='grid'
                alignContent='center'
              >
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.0}
                  duration={1}
                  initialX={0}
                  initialY={10}
                >
                  <Heading
                    color='brand.violet'
                    fontWeight='normal'
                    fontSize={{
                      base: '4xl',
                      lg: '6xl',
                    }}
                  >
                    Leitbild und Philosophie:
                  </Heading>
                </FadeInAnimation>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.4}
                  duration={0.8}
                  initialX={0}
                  initialY={10}
                >
                  <Text
                    my={8}
                    color='white'
                    fontWeight='normal'
                    fontSize={{
                      base: 'lg',
                      lg: 'xl',
                    }}
                  >
                    Unser eigener Anspruch der Offenheit, Zuverlässigkeit,
                    Wertschätzung und Leidenschaft findet sich in allem wieder,
                    was wir tun.
                  </Text>
                </FadeInAnimation>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.8}
                  duration={0.8}
                  initialX={0}
                  initialY={10}
                >
                  <Text
                    color='white'
                    fontWeight='normal'
                    fontSize={{
                      base: 'lg',
                      lg: 'xl',
                    }}
                  >
                    Ziel ist es, Vertrauen zu schaffen. Wir begeistern
                    Mitarbeiter und Unternehmen mit einem Personalmanagement,
                    neu gedacht, flexibel, nachhaltig, sicher und innovativ.
                  </Text>
                </FadeInAnimation>
              </Box>
            </Container>
            <Container
              position='absolute'
              top={'155vh'}
              variant='layoutContainer'
              mt={8}
            >
              <Box
                display='grid'
                alignContent='center'
                w={{ base: '80%', md: '50%' }}
                h='100vh'
                pr={{ base: '0%', md: '25%' }}
                border='3px dashed transparent'
                position='sticky'
                top='0'
                mx='auto'
              >
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.0}
                  duration={1}
                  initialX={0}
                  initialY={10}
                >
                  <Heading
                    color='brand.violet'
                    fontWeight='normal'
                    fontSize={{
                      base: '4xl',
                      lg: '6xl',
                    }}
                  >
                    Unsere Werte:
                  </Heading>
                </FadeInAnimation>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.4}
                  duration={0.8}
                  initialX={0}
                  initialY={10}
                >
                  <Text
                    my={8}
                    color='white'
                    fontWeight='normal'
                    fontSize={{
                      base: 'lg',
                      lg: 'xl',
                    }}
                  >
                    Werte wie Vertrauen, Wertschätzung, Offenheit und
                    Zuverlässigkeit sind besonders in der heutigen Zeit nicht
                    mehr nur ein Qualitätsmerkmal, sondern eine klare
                    Notwendigkeit im Umgang mit Mitarbeitern, Partnern und
                    Kunden und um somit erfolgreich zu sein.
                  </Text>
                </FadeInAnimation>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.8}
                  duration={0.8}
                  initialX={0}
                  initialY={10}
                >
                  <Text
                    color='white'
                    fontWeight='normal'
                    fontSize={{
                      base: 'lg',
                      lg: 'xl',
                    }}
                  >
                    Bei uns sind dies nicht nur Worte in einer Präsentation. Wir
                    leben und wissen dies, und wir definieren uns über diese
                    Werte.
                  </Text>
                </FadeInAnimation>
              </Box>
            </Container>
          </>
        )}
      </ScrollAnimationContainer>

      <TeamTri />
      <FadeInAnimation threshold={0.4} duration={1} delay={0.7}>
        <RouterLink isExternal={'false'} link={'/team'}>
          <Button
            mt={2}
            mb={16}
            mx='auto'
            px={14}
            pt={4}
            pb={9}
            fontSize='md'
            textAlign='center'
            variant={'solid'}
            color='white'
            bg='brand.violet'
          >
            Unser Team
          </Button>
        </RouterLink>
      </FadeInAnimation>
      <ImgTextModule
        src={'All-1.mp4'}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h='55vh'
        mediaOpacity={0.7}
        bgColor='black'
      >
        <Container variant='layoutContainer' mb={16}>
          <Box p={{ base: 0, lg: '8' }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color='white'
                fontWeight='normal'
                fontSize={{ base: '2xl', lg: '2xl' }}
              >
                Arbeitnehmer
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.4}
              initialX={0}
              initialY={-10}
            >
              <Text
                color='brand.green'
                fontWeight='normal'
                fontSize={{ base: '3xl', lg: '6xl' }}
              >
                Karriere und
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.4}
              initialX={0}
              initialY={-10}
            >
              <Text
                color='brand.yellow'
                fontWeight='normal'
                fontSize={{ base: '3xl', lg: '6xl' }}
              >
                persönliche Entwicklung.
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color='brand.gray.1000'
                mb={8}
                maxW={{ base: '100%', lg: '50%' }}
                fontSize='xl'
                lineHeight={'short'}
              >
                Arbeitnehmer finden bei uns langfristige Karriereoptionen
                genauso wie neue Herausforderungen vornehmlich im Industrie-,
                Gewerbe- und Officesegment.
              </Text>
            </FadeInAnimation>
            <FadeInAnimation threshold={0.4} duration={1} delay={0.7}>
              <RouterLink isExternal={'false'} href={'/stellen'}>
                <Button
                  mt={2}
                  px={14}
                  pt={4}
                  pb={9}
                  fontSize='md'
                  textAlign='center'
                  variant={'solid'}
                  color='white'
                  bg='brand.violet'
                >
                  Freie Stellen
                </Button>
              </RouterLink>
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>
      <KontaktFooter />
    </>
  );
};

export default IndexPage;
