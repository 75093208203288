import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Button,
  useColorMode,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import RouterLink from './Utility/Routing/RouterLink';
import ImgTextModule from './elements/ImgTextModule';
import FadeInAnimation from './UX/FadeInAnimation';
import MagicText from './UX/MagicText';

function TeamTri() {
  return (
    <Container variant='layoutContainer' my={16}>
      <Container variant='layoutContainer' mt={8}>
        <Box
          p={{
            base: 4,
            lg: '8',
          }}
        >
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.4}
            initialX={0}
            initialY={-10}
          >
            <Text
              color='brand.gray.300'
              textAlign='center'
              fontWeight='normal'
              fontSize={{
                base: 'md',
                lg: 'lg',
              }}
            >
              Leadership & Management
            </Text>
            <Text
              textAlign='center'
              color='brand.yellow'
              fontWeight='normal'
              fontSize={{
                base: '4xl',
                lg: '6xl',
              }}
            >
              Am Puls der Zeit.
            </Text>
          </FadeInAnimation>
        </Box>
      </Container>
      <Flex
        gap={0.5}
        direction={{
          base: 'column',
          md: 'row',
        }}
      >
        <ImgTextModule
          src={'MaikSmall.mp4'}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          w={{
            base: '100%',
            md: '33%',
          }}
          h={'33vh'}
          mediaOpacity={0.8}
          bgColor='brand.gray.100'
        >
          <Container
            variant='layoutContainer'
            display='flex'
            flexDirection='column'
            justifyContent={'flex-end'}
            h={'33vh'}
            pb={8}
          >
            <Box
              p={{
                base: 0,
                lg: '8',
              }}
            >
              <FadeInAnimation threshold={0.4} duration={1} delay={0.7}>
                <RouterLink isExternal={'false'} link={'/team/maikwieting'}>
                  <Button
                    my={2}
                    px={14}
                    pt={4}
                    pb={9}
                    fontSize='md'
                    textAlign='center'
                    variant={'solid'}
                    color='white'
                    bg='brand.violet'
                  >
                    Maik Wieting
                  </Button>
                </RouterLink>
              </FadeInAnimation>
            </Box>
          </Container>
        </ImgTextModule>{' '}
        <ImgTextModule
          src={'NielsSmall.mp4'}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h={'33vh'}
          w={{
            base: '100%',
            md: '33%',
          }}
          mediaOpacity={0.8}
          bgColor='brand.gray.100'
        >
          <Container
            variant='layoutContainer'
            display='flex'
            flexDirection='column'
            justifyContent={'flex-end'}
            h={'33vh'}
            pb={8}
          >
            <Box
              p={{
                base: 0,
                lg: '8',
              }}
            >
              <FadeInAnimation threshold={0.4} duration={1} delay={0.7}>
                <RouterLink isExternal={'false'} link={'/team/nilsdykstra'}>
                  <Button
                    my={2}
                    px={14}
                    pt={4}
                    pb={9}
                    fontSize='md'
                    textAlign='center'
                    variant={'solid'}
                    color='white'
                    bg='brand.violet'
                  >
                    Nils Dykstra
                  </Button>
                </RouterLink>
              </FadeInAnimation>
            </Box>
          </Container>
        </ImgTextModule>{' '}
        <ImgTextModule
          src={'Silvio.mp4'}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          w={{
            base: '100%',
            md: '33%',
          }}
          h={'33vh'}
          mediaOpacity={0.8}
          bgColor='brand.gray.100'
        >
          <Container
            variant='layoutContainer'
            display='flex'
            flexDirection='column'
            justifyContent={'flex-end'}
            h={'33vh'}
            pb={8}
          >
            <Box
              p={{
                base: 0,
                lg: '8',
              }}
            >
              <FadeInAnimation threshold={0.4} duration={1} delay={0.7}>
                <RouterLink isExternal={'false'} link={'/team/silviokoehler'}>
                  <Button
                    my={2}
                    px={14}
                    pt={4}
                    pb={9}
                    fontSize='md'
                    textAlign='center'
                    variant={'solid'}
                    color='white'
                    bg='brand.violet'
                  >
                    Silvio Köhler
                  </Button>
                </RouterLink>
              </FadeInAnimation>
            </Box>
          </Container>
        </ImgTextModule>
      </Flex>
    </Container>
  );
}

export default TeamTri;
